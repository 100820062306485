<template>
  <div class="marketplace-item-card">
    <div class="card">
      <div class="card-image">
        <figure class="image is-4by3">
          <img :src="marketplaceItem.images[0]" alt="Placeholder image"/>
        </figure>
      </div>
      <div class="card-content">
        <div class="media">
          <div class="media-content">
            <p class="title is-4">{{ marketplaceItem.name }}</p>
            <p class="is-6">
              <b>Asking Price:</b> ${{ formatPrice(marketplaceItem.askingPrice) }}
            </p>
            <p class="is-6"><b>Quantity:</b> {{ marketplaceItem.quantity }}</p>
            <p class="is-6"><b>Location:</b> {{ marketplaceItem.location }}</p>
          </div>
        </div>
        <div class='content' v-if="store.userId===marketplaceItem.posterId">
          <div class="modify-buttons">
            <button class="button is-warning is-light">Edit</button>
            <button class="button is-danger is-light" @click="remove">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useUserStore} from "../store";
import MarketplaceService from "../services/marketplaceService"

export default {
  name: "MarketplaceItemCard",
  props: ["marketplaceItem"],
  setup() {
    let store = useUserStore()
    store.hydrateFromLocalStorage();
    return {store}
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    remove() {
      this.$swal({
        title: "Delete this listing?",
        text: "Are you sure? You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!"
      }).then((result) => { // <--
        if (result.value) { // <-- if confirmed
          MarketplaceService.delete(this.$route.params.id)
          this.$router.push("/marketplace")
        }
      });

    },
  }
}
</script>

<style lang="scss" scoped>
.modify-buttons {
  display: flex;
  justify-content: space-around;

  .button {
    width: 100%;
    margin: 5px;
    z-index: 10;
  }
}
</style>